import React, { useContext } from "react";
import {
    GelLabel,
    GelBoxLayout,
    GelContainerLite,
    GelIcon,
    GelTag,
    GelScreenDetectorContext,
    GelParagraph
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { CLAIM_STATUS_TYPE } from "../../constants/constants";
import Tag from "../../common/components/Tag";



const BenefitType = (props) => {
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    return (
        <GelContainerLite style={{
            padding: props.isFromHomePage ? "25px 16px" :
                `${getGelTokens().global.sizeBaseX6}px ${getGelTokens().global.sizeBaseX10}px ${getGelTokens().global.sizeBaseX6}px ${getGelTokens().global.sizeBaseX10}px`
        }}>
            <GelBoxLayout space={!isXsScreen(screen) ? [7, 0.1, 0.04] : [1, 0.01, 0.05]} gutter={getGelTokens().global.sizeBaseHalf}
                style={{
                    marginRight: props.isFromHomePage ?'-14px' : '0px',
                    marginLeft: '0px'
                }}
                childStyle={{
                    paddingLeft: '0px',
                    paddingRight: '0px'
                }}>
                <GelLabel style={{
                    display: "block"
                }}>{props.benefitTypeText}
                </GelLabel>
                {(props.description === CLAIM_STATUS_TYPE.APPROVED ||
                    props.description === CLAIM_STATUS_TYPE.PAID ||
                    props.description === CLAIM_STATUS_TYPE.FSD) &&
                    <Tag text={props.friendlyStatus} status="success" />}
                {(props.description === CLAIM_STATUS_TYPE.BR ||
                    props.description.includes(CLAIM_STATUS_TYPE.RTS)) &&
                    <Tag text={props.friendlyStatus} status="warning" />}
                {(props.description === CLAIM_STATUS_TYPE.CS ||
                    props.description === CLAIM_STATUS_TYPE.CN ||
                    props.description === CLAIM_STATUS_TYPE.ST ||
                    props.description === CLAIM_STATUS_TYPE.RFI) &&
                    <Tag text={props.friendlyStatus} status="danger" />}
                {props.isExpandable &&
                    <GelIcon name={props.showBenefitType ? "ChevronUp" : "ChevronDown"} color={getGelTokens().global.themeColorIconDefault} width="20px" height="20px" padding="0px 20px"></GelIcon>
                }

            </GelBoxLayout>
            <GelParagraph style={{fontSize:14, fontWeight: 400 }}>Claim number: {props.claimNumber}</GelParagraph>
        </GelContainerLite>
    );
}

export default BenefitType;