import React, { useContext, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { AccountApi } from '../../common/api/auth.provider';
import { UserContext } from '../../common/usercontext/user.context';
import { OktaErrors } from '../../common/models/enums/oktaErrors.model';

const Activate: React.FC = () => {
    const navigate = useNavigate();
    const { setUserContext } = useContext(UserContext);
    const { recoverytoken } = useParams();

    const verifyToken = async (token: string) => {
        try {
            const response = await AccountApi.verifyRecoveryToken(token);
            const data = await response.json();

            if (!data.errorCode) {
                const restPasswordStateToken = data.stateToken;
                const userId = data._embedded.user.profile.login;
                setUserContext({
                    memberDetails: {
                        factorId: '',
                        stateToken: restPasswordStateToken,
                        mobileNumber: '',
                        oktaEmail: userId
                    },
                    claims: []
                });
                navigate("/createPassword");
            } else {
                if (data.errorCode === OktaErrors.EXPIRED_OR_USED_LINK) {
                    navigate("/error", { state: { errorCode: OktaErrors.EXPIRED_OR_USED_LINK } })
                } else {
                    console.error('verifyRecoveryToken data failed');
                    navigate("/confirmMember");
                }
            }
        } catch (httpError) {
            console.error('verifyRecoveryToken Failed. Please try again', httpError);
        }
    };

    useEffect(() => {
        if (recoverytoken) {
            verifyToken(recoverytoken);
        }
    }, [recoverytoken]);


    return <></>

};

export default Activate;
