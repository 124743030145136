import React, { useContext, useEffect, useState } from "react";
import {
    GelScreenDetectorContext,
    GelRowLayout,
    GelContainerLite,
    GelParagraph,
    GelHeading2,
    GelSpinner,
    GelHeading6,
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { GET_NEED_HELP_PAGE_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import axios from "axios";
import { AdobePageName, API } from "../../constants/constants";
import Card from "../../common/components/card";
import { useCookies } from "react-cookie";
import styled from "@emotion/styled";
import ResponsiveContainer from '../common/responsive.container';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../common/usercontext/user.context";
import { AdodeAnalytics } from "../../common/analytics/adobe-analytics";
import Accordion from "../../common/components/Accordion";
import ContactUsAlert from "../../common/components/ContactUsAlert";

const NeedHelpPage = () => {

    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const [contentDataLoaded, setcontentDataLoaded] = useState(false);
    const [contentfulData, setcontenfulData] = useState<null | { mmcNeedHelpCollection: any }>(null);
    const [ssoCookies] = useCookies(['tpid']);
    const navigate = useNavigate();
    const { contextData } = useContext(UserContext)

    if (contextData.memberDetails?.displayLegalDisclosure)
        navigate("/yourDeclaration");

    useEffect(() => {
        axios.post(API.CONTENTFUL_URL, {
            query: GET_NEED_HELP_PAGE_CONTENT_QUERY,
            variables: {
                fundName: ssoCookies.tpid
            }
        })
            .then(async (response) => {
                setcontentDataLoaded(true);
                setcontenfulData(response.data?.data);
            });
        AdodeAnalytics.PushPageLoadEvent(ssoCookies.tpid, AdobePageName.NeedHelp);
    }, []);

    if (!contentDataLoaded)
        return <GelSpinner medium overlay />

    const PageHeader = styled.div(() => ({
        padding: isXsScreen(screen) ? `${getGelTokens().global.sizeBaseX15}px ${getGelTokens().global.sizeBaseX5}px` : `${getGelTokens().global.sizeBaseX15}px ${getGelTokens().global.sizeBaseX10}px`,
        backgroundColor: getGelTokens().global.themeColorBackgroundLight
    }));

    const QuestionDescription = contentfulData?.mmcNeedHelpCollection?.items[0].questionDescription;

    return (
        <GelContainerLite style={{ padding: getGelTokens().global.sizeNone, overflow: "hidden" }}>
            <PageHeader >
                <GelHeading2>{contentfulData?.mmcNeedHelpCollection?.items[0].pageHeader}</GelHeading2>
                <GelParagraph style={{ paddingTop: getGelTokens().global.sizeBaseX3 }}>{contentfulData?.mmcNeedHelpCollection?.items[0].pageSubtext}</GelParagraph>
            </PageHeader>

            <ResponsiveContainer>
                <GelRowLayout style={{ padding: isXsScreen(screen) ? `${getGelTokens().global.sizeBaseX10}px ${getGelTokens().global.sizeBaseX5}px` : `${getGelTokens().global.sizeBaseX5}px ${getGelTokens().global.sizeBaseX10}px` }}>
                    <div>
                        <ContactUsAlert 
                            title={contentfulData?.mmcNeedHelpCollection?.items[0].questionHeader} 
                            body={QuestionDescription.json.content[0].content[0].value} 
                            style={{marginBottom: isXsScreen(screen) ? '24px' : '40px'}}/>

                        {(contentfulData?.mmcNeedHelpCollection?.items[0]?.faqs?.sections || []).map((section, sectionIndex, sectionArr) =>
                            <div
                                key={sectionIndex}
                                style={{ paddingBottom: sectionIndex !== sectionArr.length - 1 ? '24px' : '0px' }}
                            >
                                <GelHeading2 style={{ color: '#191919', paddingBottom: '8px' }}>{section?.title}</GelHeading2>
                                <GelParagraph style={{ color: '#667085', paddingBottom: '40px' }}>{section?.subtitle}</GelParagraph>
                                {(section?.subsections || []).map((subsection, subsectionIdx, subsectionarr) =>
                                    <div
                                        key={subsectionIdx}
                                        style={{ paddingBottom: subsectionIdx !== subsectionarr.length - 1 ? '40px' : '0px' }}
                                    >
                                        <GelHeading6 style={{ color: '#191919', paddingBottom: '8px', fontSize: '20px' }}>{subsection?.title}</GelHeading6>
                                        <GelParagraph style={{ color: '#667085', paddingBottom: '16px' }}>{subsection?.subtitle}</GelParagraph>

                                        {(subsection?.questions || [])?.map((question, questionIdx, questionArr) =>
                                            <Accordion title={question?.question} body={question?.answer} key={questionIdx} style={{ paddingBottom: questionIdx !== questionArr.length - 1 ? '16px' : '0px' }} />
                                        )}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </GelRowLayout>
            </ResponsiveContainer>
        </GelContainerLite>
    );
}

export default NeedHelpPage;