import React, { useContext, useEffect, useState } from 'react';
import { AdobePageName, API } from '../../constants/constants';
import { GET_YOUR_DECLARATION_PAGE_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import {
    GelScreenDetectorContext,
    GelRowLayout,
    GelContainerLite,
    GelParagraph,
    GelHeading3,
    GelBoxLayout,
    GelButton,
    GelSpinner
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { UserContext } from '../../common/usercontext/user.context';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import FundBanner from '../common/fundBanner';
import { SAVE_LEGAL_DISCLOSURE_QUERY } from '../../graphql/queries/graphql-mutations';
import SESSION_STORAGE_KEY from '../../constants/storage.constant';
import { AdodeAnalytics } from '../../common/analytics/adobe-analytics';
import { ACTIVATION_EMAIL } from '../../graphql/queries/graphql-mutations';


const YourDeclaration = () => {
    const navigate = useNavigate();
    const { screen, isLargerThanSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const { contextData, setUserContext } = useContext(UserContext);
    const [contentDataLoaded, setcontentDataLoaded] = useState(false);
    const [contentfulData, setcontenfulData] = useState<null | { mmcYourDeclarationCollection: any }>(null);
    const [ssoCookies] = useCookies(['aid', 'rid', 'ssoid', 'tpid']);

    if (!contextData.memberDetails?.displayLegalDisclosure) {
        navigate("/error");
    }
    
    useEffect(() => {
        axios.post(API.CONTENTFUL_URL, {
            query: GET_YOUR_DECLARATION_PAGE_CONTENT_QUERY,
            variables: {
                fundName: ssoCookies.tpid
            }
        })
            .then(async (response) => {
                setcontentDataLoaded(true);
                setcontenfulData(response.data?.data);
            });

        if (window.sessionStorage.getItem(SESSION_STORAGE_KEY.SEND_ACTIVATION_EMAIL)) {
            sendSuccessfulActivationEmail();
        }

        AdodeAnalytics.PushPageLoadEvent(ssoCookies.tpid, AdobePageName.YourDeclaration);
    }, []);

    const sendSuccessfulActivationEmail = () => {
        let requestData = {
            userName: window.sessionStorage.getItem(SESSION_STORAGE_KEY.SEND_ACTIVATION_EMAIL)
        };
        axios.post(API.BASEURL, {
            query: ACTIVATION_EMAIL,
            variables: {
                memberActivationSuccessModel: requestData
            }
        },
            {
                headers: {
                    'x-aid': ssoCookies.aid,
                    'x-rid': ssoCookies.rid,
                    'ssoid': ssoCookies.ssoid,
                    'tpid': ssoCookies.tpid
                }
            })
            .then(async (response) => {
                if (response) {
                    console.log("Activation email sent")
                }
                else {
                    console.log("Failed to send activation email")
                }
                window.sessionStorage.setItem(SESSION_STORAGE_KEY.SEND_ACTIVATION_EMAIL, "");
            })
            .catch(httpError => {
                console.log('Failed to send successful activation email', httpError);
            });
    }
    
    const onContinue = () => {
        axios.post(API.BASEURL, {
            query: SAVE_LEGAL_DISCLOSURE_QUERY,
        },
            {
                headers: {
                    'x-aid': ssoCookies.aid,
                    'x-rid': ssoCookies.rid,
                    'ssoid': ssoCookies.ssoid,
                    'tpid': ssoCookies.tpid,
                    'sid': window.sessionStorage.getItem(SESSION_STORAGE_KEY.SESSIONID_TOKEN)
                }
            })
            .then(async (response) => {
                if (response.data?.data?.acceptLegalDisclosureForMemberClaims === true) {
                    if (contextData.memberDetails) {
                        contextData.memberDetails.displayLegalDisclosure = false;
                        setUserContext(contextData);
                    }
                    navigate("/claims");
                }
            });
    };

    if (!contentDataLoaded)
        return (<GelSpinner medium overlay />)

    return (
        <GelContainerLite style={{ 
            paddingRight: getGelTokens().global.sizeNone,
            paddingLeft: getGelTokens().global.sizeNone,
            paddingBottom: getGelTokens().global.sizeBaseUnit * 15, 
            paddingTop:getGelTokens().global.sizeBaseUnit * 15,  
            overflow: "hidden" }}>
            <GelBoxLayout space={[1, 2]} style={{
                alignItems: "baseLine"
            }}>
                <GelRowLayout id="yourDeclaration" style={{ padding: `${getGelTokens().global.sizeBaseX3}px ${getGelTokens().global.sizeBaseUnit * 16}px` }}>
                    <GelHeading3 style={{ paddingTop: getGelTokens().global.sizeBaseX7}}>
                        {contentfulData?.mmcYourDeclarationCollection.items[0]?.pageHeader}
                    </GelHeading3>
                    <GelParagraph style={{}}> {contentfulData?.mmcYourDeclarationCollection.items[0]?.declarationStatement}</GelParagraph>
                    <GelParagraph style={{ paddingBottom: getGelTokens().global.sizeBaseX7 }}> {contentfulData?.mmcYourDeclarationCollection.items[0]?.declarationStatement2}</GelParagraph>
                    <GelButton
                        name="" // Makes it a managed component
                        style={{width: "100%"}}
                        primary large submit
                        onClick={onContinue}
                    >
                        {contentfulData?.mmcYourDeclarationCollection.items[0]?.buttonText}
                    </GelButton>
                </GelRowLayout>

                {isLargerThanSmScreen(screen) &&
                    <FundBanner></FundBanner>}
            </GelBoxLayout>
        </GelContainerLite>
    )

};

export default YourDeclaration  