import React, { useEffect, useState } from "react";
import { ReactNode } from "react";
import {
  GelContainerLite,
  GelRowLayout,
  GelLabel,
  GelForm,
  GelFormField,
  GelButton,
  GelIcon,
  GelBoxLayout,
  GelModal
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AdobeClickEvents, AdobePageName, API } from "../../constants/constants";
import { GET_STATUS_DESCRIPTION_PAGE_CONTENT_QUERY } from "../../graphql/queries/graphql-contentful-queries";
import { useCookies } from "react-cookie";
import ClaimStatus from "./claimStatus";
import { AdodeAnalytics } from "../analytics/adobe-analytics";

interface Props {
  title: string;
  subTitle?: string;
  icon?: ReactNode;
  buttonText?: string;
  children: ReactNode;
  showFooter: boolean;
  lightHeader?: boolean;
  claimAssesmentStatus?: ReactNode;
}

const Card = ({ title, subTitle, icon, buttonText, children, showFooter, lightHeader, claimAssesmentStatus }: Props) => {
  const [contentfulData, setcontenfulData] = useState<null | { mmcStatusDescriptionPageCollection: any }>(null);
  const navigate = useNavigate();
  const [claimCookie, setClaimCookie] = useCookies(['selectedClaim']);
  const [ssoCookies] = useCookies(['aid', 'rid', 'ssoid', 'tpid']);
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    if (!contentfulData) {
      axios.post(API.CONTENTFUL_URL, {
        query: GET_STATUS_DESCRIPTION_PAGE_CONTENT_QUERY,
        variables: {
          fundName: ssoCookies.tpid
        }
      })
        .then(async (response) => {
          setcontenfulData(response.data?.data);
        });
    }
  }, []);

  const onSubmit = () => {
    setClaimCookie('selectedClaim', title);
    AdodeAnalytics.PushUserClickEvent(ssoCookies.tpid, AdobeClickEvents.ButtonClick, AdobePageName.ClaimsDashboard,title, buttonText!);

    navigate("/claimdetails", { state: { claimTypeName: title } });
  }

  const Header = styled.div(() => ({
    minHeight: getGelTokens().global.sizeBaseUnit * 15,
    color: getGelTokens().global.themeColorBackgroundDefault,
    fontSize: 10,
    fontFamily: getGelTokens().global.fontFamilySans,
    padding: getGelTokens().global.sizeBaseX4,
    borderRadius: `${getGelTokens().global.sizeBaseUnit * 2}px ${getGelTokens().global.sizeBaseUnit * 2}px 0px 0px`,
    margin: "0px"
  }));

  const Body = styled.div(() => ({
    color: getGelTokens().global.themeColorBackgroundDefault,
    border: "0px solid",
    borderColor: getGelTokens().global.themeColorGrayT20,
    borderRadius: `0px 0px ${getGelTokens().global.sizeBaseUnit * 2}px ${getGelTokens().global.sizeBaseUnit * 2}px`
  }));

  const Footer = styled.div(() => ({
    height: getGelTokens().global.sizeBaseX2 * 10,
    color: getGelTokens().global.themeColorBackgroundDefault,
    paddingTop: getGelTokens().global.sizeBaseX2
  }));

  const onClose = () => {
    setOpenModal(false);
  };

  const openModalWindow=()=> {
    setOpenModal(true);
    AdodeAnalytics.PushToolTipClickEvent(ssoCookies.tpid, AdobeClickEvents.TooltipClick,  subTitle!);
  }

  return (
    <GelContainerLite gutter="medium" style={{
      backgroundColor: getGelTokens().global.themeColorBackgroundDefault,
      border: "1px solid",
      borderColor: getGelTokens().global.themeColorGrayT20,
      padding: "0px",
      borderRadius: getGelTokens().global.sizeBaseUnit * 2,
    }}
    >
      <Header style={{
        backgroundColor: lightHeader ? getGelTokens().global.themeColorGrayT05 :
          getGelTokens().global.themeColorBackgroundInverse
      }}>
        <GelRowLayout gutter="small">
          <h1 style={{
            margin: "0px",
            color: lightHeader ? getGelTokens().global.themeColorGrayT90 : getGelTokens().global.themeColorBackgroundDefault
          }}>{title}</h1>
          <GelBoxLayout distribution="start" wrap={false} space="auto" gutter={getGelTokens().global.sizeNone}>
            {icon &&
              <GelIcon style={{ paddingRight: getGelTokens().global.sizeBaseX2 }}
                name={icon}
                width={getGelTokens().global.sizeBaseX5}
                height={getGelTokens().global.sizeBaseX5}
                color={lightHeader ? getGelTokens().global.themeColorGrayT90 : getGelTokens().global.themeColorBackgroundDefault}
                onClick={() => openModalWindow()} />
            }
            {subTitle &&
              <GelLabel style={{
                fontWeight: 10,
                display: "inline-block",
                color: lightHeader ? getGelTokens().global.themeColorGrayT90 : getGelTokens().global.themeColorBackgroundDefault
              }}>{subTitle}</GelLabel>
            }
          </GelBoxLayout>

          {openModal &&
            <GelModal
              width="sm"
              height={getGelTokens().global.sizeBaseUnit * 170}
              open={openModal}
              onClose={() => setOpenModal(false)}
              sticky
              title={contentfulData?.mmcStatusDescriptionPageCollection.items[0]?.pageTitle}
              action={
                <GelBoxLayout gap="small" space="auto">
                  <GelButton
                    secondary medium
                    width={getGelTokens().global.sizeBaseUnit * 22}
                    onClick={onClose}
                  >
                    {contentfulData?.mmcStatusDescriptionPageCollection.items[0]?.buttonText}
                  </GelButton>
                </GelBoxLayout>
              }
            >
              {
                contentfulData?.mmcStatusDescriptionPageCollection.items[0]?.claimStatuses.map(claimStatus => (
                  <ClaimStatus statusType={claimStatus.StatusType} statusHeader={claimStatus.StatusHeader} statusDescription={claimStatus.StatusDescription} />
                ))
              }
            </GelModal>
          }
        </GelRowLayout>
      </Header>
      <Body>{children}</Body>
      {showFooter &&
        <Footer style={{padding: `${getGelTokens().global.sizeBaseX5}px ${getGelTokens().global.sizeBase * 2.5}px 0px ${getGelTokens().global.sizeBase * 2.5}px`, boxSizing: 'content-box'}}>
          <GelForm
            labelAtTop={true}
            onSubmit={onSubmit}>
            <GelFormField width="auto">
              <GelButton style={{
                width: "100%",
              }}
                name=""
                primary large submit>
                {buttonText}
              </GelButton>
            </GelFormField>
          </GelForm>
        </Footer>}
    </GelContainerLite>
  );
}


export default Card;