import styled from "@emotion/styled";
import { getGelTokens } from '@tal-gel/theming';

export const PageSideNavXsStyle = {
    backgroundColor: getGelTokens().global.themeColorBackgroundInverse,
    "max-height": getGelTokens().global.sizeBaseUnit * 100,
    "overflow":"hidden"
};

export const PageSideNavStyle = {
    backgroundColor: getGelTokens().global.themeColorBackgroundInverse
};

export const NavMenuContainer = styled.div((props:React.CSSProperties) => ({
    position: "relative",
    height: 'calc(100% - 60px)',
    width: getGelTokens().global.sizeBaseUnit * 64,
    color: getGelTokens().global.themeColorGrayT05
}));

export const NavMenuMobContainer = styled.div((props:React.CSSProperties) => ({
    position: "relative",
    height: 'calc(100% - 60px)',
    color: getGelTokens().global.themeColorGrayT05
}));

export const NavItemList = styled.div(() => ({
    listStyleType: "none",
    margin: 0,
    paddingInlineStart: 0
}));

export const StyledListItem = styled.div((props: React.CSSProperties) => ({
    height: getGelTokens().global.sizeBaseUnit * 15,
    display: "flex",
    alignItems: "center",
    color: "white",
    borderRight: "0",
    whiteSpace: "initial",
    "&:hover": {
        cursor: "pointer",
    },
    border: `1px solid ${getGelTokens().brand.brandColorPrimary2T90}`,
})
);

export const StyledListHeader = styled.div((props: React.CSSProperties) => ({
    height: getGelTokens().global.sizeBaseUnit * 15,
    padding: "0 16px",
    display: "flex",
    alignItems: "center",
    color: "white",
    borderRight: "0",
    whiteSpace: "initial",
    "&:hover": {
        cursor: "pointer",
    },
    alignSelf: "end",
    justifyContent: "space-between;"
})
);

export const EnabledMenuStyle = {
    color: getGelTokens().global.themeColorWhite,
    borderLeft: `4px solid ${getGelTokens().global.themeColorGrayT30}`,
};
export const ActiveMenuStyle = {
    color: getGelTokens().global.themeColorBackgroundSuccess,
    borderLeft: `4px solid ${getGelTokens().global.themeColorBackgroundSuccess}`
};

export const EnabledMenuLableStyle = { 
    color: getGelTokens().global.themeColorGrayT30, 
    fontWeight: getGelTokens().global.fontWeightLight,
    paddingLeft: getGelTokens().global.sizeBaseUnit * 2,
    cursor: 'pointer' 
}

export const ActiveMenuLableStyle = { 
    color: getGelTokens().global.themeColorBackgroundSuccess, 
    fontWeight: getGelTokens().global.fontWeightBold,
    paddingLeft: getGelTokens().global.sizeBaseUnit * 2,
    cursor: 'pointer' 
}
