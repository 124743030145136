import React, { useContext } from 'react';
import styled from "@emotion/styled"
import {
    GelIcon,
    GelBoxLayout,
    GelTag,
    GelParagraph,
    GelScreenDetectorContext
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';


const Tag = ({ text, status }) => {
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;

    return (
        <TagWrapper isMobile={!isXsScreen(screen)} status={status}>
            <GelBoxLayout
                distribution="center"
                alignment="center"
                space={[0.5, 10]}
                style={{
                    gap: `${getGelTokens().global.sizeBaseUnit}px`,
                    paddingLeft: `${getGelTokens().global.sizeBaseX2}px`,
                    paddingRight: `${getGelTokens().global.sizeBaseX2}px`,
                    ...(isXsScreen(screen) ? { maxWidth: '13em' } : {})
                }}
                childStyle={{
                    paddingLeft: `${getGelTokens().global.sizeNone}px`,
                    paddingRight: `${getGelTokens().global.sizeNone}px`
                }}
            >
                <GelIcon
                    name="Circle"
                    width={getGelTokens().global.sizeBaseUnit * 2}
                    color = {getStatusIconColor(status)}
                />
                <GelParagraph
                    style={{
                        fontSize: getGelTokens().global.sizeBaseX3,
                        paddingRight: getGelTokens().global.sizeBaseUnit,
                        color: getGelTokens().global.themeColorTextDark
                    }}
                >
                    {text}
                </GelParagraph>
            </GelBoxLayout>
        </TagWrapper>
    );
};

const getStatusIconColor = (status) => {
    switch (status) {
        case 'success':
            return getGelTokens().global.themeColorBackgroundSuccess;
        case 'danger':
            return getGelTokens().global.themeColorBackgroundDanger;
        case 'warning':
            return getGelTokens().global.themeColorBackgroundWarning;
    }
};

const getStatusColor = (status) => {
    switch (status) {
        case 'success':
            return getGelTokens().global.themeColorBackgroundSuccessLight;
        case 'danger':
            return getGelTokens().global.themeColorBackgroundDangerLight;
        case 'warning':
            return getGelTokens().global.themeColorBackgroundWarningLight;
    }
};

interface TagWrapperProps {
    status: string;
    isMobile: boolean;
}

const TagWrapper = styled.div<TagWrapperProps>`
    justify-content: center;
    align-items: center;
    display: inline-flex;
    position: relative;
    font-family: "Pluto Sans Condensed", Arial, sans-serif;
    font-weight: 500;
    line-height: 1;
    text-decoration: none;
    margin-right: 8px;
    margin-top: 4px;
    border-radius: 16px;
    color: #000000;
    background-color: ${(props) => getStatusColor(props.status)};
    padding: 2px 8px;
    font-size: 12px;
    border: 1px solid ${(props) => getStatusColor(props.status)};
    gap: 8px;
    cursor: auto;

    ${(props) =>
        props.isMobile &&
        `
        height: 24px;
        padding: 8px 8px;
    `}
`;

export default Tag;
