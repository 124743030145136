import React, { useEffect, useState } from "react";
import {
    GelContainerLite,
    GelBoxLayout,
    GelLabel,
    GelIcon,
    GelSpinner
} from
    '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { useLocation } from "react-router-dom";
import axios, { HttpStatusCode } from "axios";
import { useCookies } from "react-cookie";
import { API } from "../../constants/constants";
import { GET_ERROR_PAGES_CONTENT_QUERY } from "../../graphql/queries/graphql-contentful-queries";
import { OktaErrors } from "../../common/models/enums/oktaErrors.model";

const ServerError: React.FC = () => {
    const location = useLocation();
    const [ssoCookies] = useCookies(["tpid"]);
    const [contentDataLoaded, setcontentDataLoaded] = useState(false);
    const [contentfulData, setcontenfulData] = useState<null | {
        mmcErrorPagesCollection: any;
    }>(null);

    useEffect(() => {
        axios
            .post(API.CONTENTFUL_URL, {
                query: GET_ERROR_PAGES_CONTENT_QUERY,
                variables: {
                    fundName: ssoCookies.tpid,
                },
            })
            .then(async (response) => {
                setcontentDataLoaded(true);
                setcontenfulData(response.data?.data);
            });
    }, []);

    if (!contentDataLoaded)
        return <GelSpinner medium overlay />;


    const ErrorMessage = (): React.ReactNode => {
        const { sessionTerminatedMessage, forbiddenMessage, expiredActivationErrorMessage, unHandledErrorMessage } = contentfulData?.mmcErrorPagesCollection?.items[0]
        const { themeColorTextDanger } = getGelTokens().global
        const { state } = location

        let errorMessage

        if (!state) {
            errorMessage = unHandledErrorMessage
        }

        switch (state?.errorCode) {
            case HttpStatusCode.Unauthorized:
                errorMessage = sessionTerminatedMessage
                break;
            case HttpStatusCode.Forbidden:
                errorMessage = forbiddenMessage
                break;
            case OktaErrors.EXPIRED_OR_USED_LINK:
                errorMessage = <div dangerouslySetInnerHTML={{ __html: expiredActivationErrorMessage }} style={{ all: "unset" }} />
                break;
            default:
                errorMessage = unHandledErrorMessage
                break;
        }

        return (
            <GelLabel style={{ color: themeColorTextDanger }}>
                <GelIcon color={themeColorTextDanger} name="AlertCircle" inline />
                {errorMessage}
            </GelLabel>
        )
    }

    return (
        <GelContainerLite gutter="xlarge">
            <GelBoxLayout space="auto">
                <ErrorMessage />
            </GelBoxLayout>
        </GelContainerLite>
    );
}

export default ServerError;