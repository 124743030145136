import React, { useEffect, useState } from 'react';
import {
    GelBoxLayout,
    GelRowLayout,
    GelIcon,
    GelLabel
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { Link } from "react-router-dom";
import {
    NavItemList,
    NavMenuContainer,
    EnabledMenuStyle,
    ActiveMenuStyle,
    EnabledMenuLableStyle,
    ActiveMenuLableStyle,
    StyledListHeader,
    StyledListItem
} from "../../styles/nav.styles";
import { NavProps } from "./nav-props";
import { AdodeAnalytics } from '../../../common/analytics/adobe-analytics';
import { useCookies } from 'react-cookie';
import { AdobeClickEvents, AdobePageName, MESSAGE_HUB_NAV_KEY } from '../../../constants/constants';
import DigiCorroBadge from './digiCorro-badge';

const SideNav = ({ onMenuHideClick, textData, activeMenuItem }: NavProps) => {
    const[selectedMenuItem, setSelectedMenuItem] = useState(activeMenuItem);
    const [ssoCookies] = useCookies(["tpid"]);

    const onMenuItemClick = (itemId) => {
        const item = textData.menuItems.find(item => item.id == itemId);
        setSelectedMenuItem(item);
        AdodeAnalytics.PushNavClickEvent(ssoCookies.tpid, AdobePageName.MenuItem,item.text);
    };

    useEffect(() => {
        setSelectedMenuItem(activeMenuItem)
    })

    return (
        <GelRowLayout >
            <GelBoxLayout>
                <StyledListHeader>
                <GelLabel id="navMenuHeaderLabel" style={{
                        color: getGelTokens().global.themeColorWhite,
                        fontWeight: getGelTokens().global.fontWeightBold}}
                    >
                        {textData.menuHeader}
                    </GelLabel>
                    <GelIcon id="menugridIcon"
                        name="MinimalLeft"
                        color={getGelTokens().global.themeColorWhite}
                        width={getGelTokens().global.sizeBaseUnit * 6}
                        style={{ float: "right" }}
                        onClick={() => onMenuHideClick()}
                    />
                </StyledListHeader>
            </GelBoxLayout>

            <NavMenuContainer>
                {textData.menuItems.map((menuItem) => (
                    <NavItemList key={menuItem.id}>
                        <Link to={menuItem.link} style={{ textDecoration: 'none' }} onClick={() => onMenuItemClick(`${menuItem.id}`)}>
                            <StyledListItem
                                style= { (menuItem.id == selectedMenuItem?.id) ? ActiveMenuStyle: EnabledMenuStyle} >
                                <GelIcon
                                    name={menuItem.icon}
                                    color={(menuItem.id == selectedMenuItem?.id) ? getGelTokens().global.themeColorBackgroundSuccess :
                                        getGelTokens().global.themeColorGrayT30}
                                    width={getGelTokens().global.sizeBaseUnit * 6}
                                    style={{ paddingLeft: getGelTokens().global.sizeBaseUnit * 2 }}
                                />
                                <GelLabel id={menuItem.text} style={ (menuItem.id == selectedMenuItem?.id) ? ActiveMenuLableStyle: EnabledMenuLableStyle }>
                                    {menuItem.text}
                                </GelLabel>
                                {menuItem.key === MESSAGE_HUB_NAV_KEY ? <DigiCorroBadge /> : null}
                            </StyledListItem>
                        </Link>
                    </NavItemList>
                ))}
            </NavMenuContainer>
        </GelRowLayout>
    );
};

export default SideNav;
