import Storage from '../common/storage.common';
import SESSION_STORAGE_KEY from '../constants/storage.constant';

export const ContextType = {
APIENDPOINT: 'apiEndPoint',
CONTENTFULENDPOINT: 'contentfulEndPoint',
};

let API_BASE = '';
let CONTENTFUL_URL = '';
let Cookie_Domain = '';

if (process.env.NODE_ENV === 'production') {
  API_BASE = '#{GRAPHQL_ENDPOINT}/graphql';
  //For production build set Ocp-Apim-Subscription-Key
  CONTENTFUL_URL = '#{CMS_API_URL}'
  Storage.setItem(SESSION_STORAGE_KEY.APIM_KEY, '#{APIM-KEY}');
  Cookie_Domain = '.urlife.net.au';
} else {
  API_BASE = 'https://localhost:44300/graphql';
  CONTENTFUL_URL = 'https://graphql.contentful.com/content/v1/spaces/9p83ob5n4kp5/environments/master?access_token=HaQT2lub60IFqqX896_XGCrVHRExaNG9pNFAJDHszYA'
  Cookie_Domain = 'localhost';
}

const EFT_BASE = process.env.NODE_ENV === 'production' ? `#{EFT_URL}` : `http://localhost:4200`;
const TFN_BASE = process.env.NODE_ENV === 'production' ? `#{TFN_URL}` : `http://localhost:4300`;

export const API = {
  BASEURL: API_BASE,  
  CONTENTFUL_URL: CONTENTFUL_URL,
  EFT_REDIRECT: EFT_BASE,
  TFN_REDIRECT: TFN_BASE
};

export const THEME_NAME: { [name: string]: string } = {
  TAL: 'tal',
  AWARE: 'aware',
  VICSUPER: 'vicSuper',
  CBUS: 'cbus',
  AUSSUPER: 'ausSuper',  
  AMP: 'amp',
  FOODSUPER:'ausfoodsuper',
  TWUSUPER:'twusuper'
}

export const THEME_CODE: { [name: string]: string } = {
  TAL: 'tal',
  AWARE: 'awr',
  VICSUPER: 'vic', 
  FOODSUPER:'auf',
  WASUPER: 'was',
  VISSF: 'vis',
  TWUSUPER: 'tws'
}

export const HOST_BRAND_MAP: { [name: string]: string } = {
  tal: THEME_NAME.TAL,
  aware: THEME_NAME.AWARE,
  vicsuper: THEME_NAME.VICSUPER,
  cbus: THEME_NAME.CBUS,
  aussuper: THEME_NAME.AUSSUPER,
  amp: THEME_NAME.AMP,
}

export const GRAPH_API_HEADERS : { [name: string]: string } = {
  ContentTypeHeader: 'Content-Type',
  ContentTypeValue : 'application/json',
  ApiKeyHeader : 'api-key',
  SsoId : 'ssoid',
  TPid: 'tpid',
  AidHeader : 'x-aid',
  RidHeader : 'x-rid',
  SsoToken : 'sa',
  AppRefToken : 'arn'
}

export const CLAIM_TYPE : { [name: string]: string } = {
  IP: 'Income Protection',
  TPD: 'Total & Permanent Disability',
  TI: 'Terminal Illness',
}

export const CLAIM_STATUS_TYPE : { [name: string]: string } = {
  RTS: 'Referred to',
  CS: 'Claim started',
  CN: 'Claim notified',
  ST: 'Started',
  BR: 'Being Reviewed',
  RFI: 'Requires Further Information',
  APPROVED: 'Approved',
  PAID: 'Paid',
  FSD: 'Finalised'
}

export const CLAIM_ASSESSMENT_STATUS : { [name: string]: string } = {
  RN: 'Review Notification',
  OTC: 'Open - TeleClaim'
}

export const supportedFileTypes = [
  '.doc',
  '.ppt',
  '.pptx',
  '.ppz',
  '.csv',
  '.xls',
  '.txt',
  '.pdf',
  '.rtf',
  '.tif',
  '.tiff',
  '.gif',
  '.mdi',
  '.dot',
  '.jpg',
  '.png',
  '.xlsx',
  '.docx',
  '.jpeg',
  '.msg'
];

export const FILE_UPLOAD_STATUS : { [name: string]: string } = {
  SUCCESS: 'success',
  FAILED: 'failed',
  SANITISED: 'sanitised',
  SCANNING: 'scanning',
  FILE_SIZE_EX: 'maxSizeExceeded',
  NOT_SUPPORTED: 'unSupportedFile',
  VULNERABLE: 'vulnerable',
  PAID: 'Paid',
  FSD: 'Finalised'
}

export const AllowedFileCountPerUpload = 15;
export const AllowedMaxFileSize = 157286400;
export const AcceptedFileTypes = supportedFileTypes.join(', ');
export const ACTIVITY_TYPE: { [name: string]: string } = {
  REQUIREMENT: 'REQUIREMENT',
  PAYMENT: 'PAYMENT',
  ACTIVITY: 'ACTIVITY'
}
export const PAYMENT_DETAILS_REQUIREMENTS = [
  'EFT DETAILS',
  'PAYMENT DETAILS OF CLIENT/BENEFICIARY'
]; 

export const TFN_DETAILS_REQUIREMENTS = [
  'Tax File Number Declaration'
];

export const DIGITAL_FORM_TYPE: { [name: string]: string } = {
  EFT: 'EFT',
  TFN: 'TFN'
}

export const CookieConfig : { [name: string]: string  } = {
  DOMAIN: Cookie_Domain,
  PATH: '/',
  SAMESITE: 'lax',
}

export const FuturePaymentNotificationId = 9999;

export enum NotificationType{
  ProceesedPayment = 1,
  Requirement = 2,
  Activity = 3,
  FuturePayment = 4 
}

const ReCaptchaSiteKey = process.env.NODE_ENV === 'production' ? `#{RECAPTCHA_SITE_KEY}` : '6LcZtwAqAAAAACQ_n-5Pctdm3_-jxvjIpBar8of9';
const ReCaptchaEnabled = process.env.NODE_ENV === 'production' ? `#{RECAPTCHA_ENABLED}` : '1';

export const ReCaptchaSettings = {
  ReCaptcha_SiteKey : ReCaptchaSiteKey,
  ReCaptcha_Enabled : ReCaptchaEnabled
}

export const NotificationItemIds = {
  FuturePayment : "FuturePayment",
  OtherDocuments : "OtherDocuments"
}

export const SessionManagement = {
  TokenReceivedOn : "tokenReceivedOn",
  LastActivity : "lastActivity"
}

export const OktaRecentSmsErrorCode = 'E0000109';



export enum SuperFundBrandingAdobe {
  awr = "AwareSuper",
  asg = "GPM",
  amp = "AMPSuper",
  aus = "AustralianSuper",
  tsf = "minesuper"
};

export const AdobeClickEvents = {
  ButtonClick : "buttonClick",
  LinkClick : 'linkClick',
  UploadDocuments: 'uploadDocuments',
  CardClick: 'cardClick',
  AccordionClick:'accordionClick',
  TooltipClick :'tooltipClick'
}

export const AdobePageName = {
  ClaimsDashboard : 'Home',
  ClaimDetails: 'Claim details',
  YourDeclaration: 'Your declaration',
  HealthSupport: 'Health and recovery support',
  YourDetails:'Your details',
  NeedHelp :'Need help',
  MenuItem : 'Navigation menu',
};

export const ADOBE_ANALYTICS_SOURCE = '#{ADOBE_ANALYTICS_URL}';
//export const ADOBE_ANALYTICS_SOURCE = 'https://assets.adobedtm.com/3236b84eee38/774535f0dce2/launch-6f5f5db7ee37.min.js';

export const MESSAGE_HUB_NAV_KEY = "messageHub";