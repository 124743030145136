import {
    GelLabel,
    GelBoxLayout,
    GelContainerLite,
    GelIcon,
    GelCollapsible,
    GelHeading6,
    GelParagraph
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import React, { useState, useContext } from "react";
import { GelScreenDetectorContext } from "@tal-gel/components";

interface AccordionProps {
    title: string;
    body: string;
    style?: React.CSSProperties; // Optional style prop
}

const Accordion = (props: AccordionProps) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;

    const toggleAccordion = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div style={{ ...props.style }}>
            <GelContainerLite
                style={{
                    borderTop: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                    borderLeft: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                    borderRight: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                    cursor: "pointer",
                    padding: '0px'
                }}
                onClick={toggleAccordion}
            >
                <GelBoxLayout
                    space={!isXsScreen(screen) ? [7, 0.5, 0.04] : [1, 0.01, 0.05]}
                    gutter={getGelTokens().global.sizeBaseUnit}
                    style={{
                        borderBottom: isExpanded ? `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}` : "",
                        padding: getGelTokens().global.sizeBaseUnit * 4,
                    }}
                >
                    <GelHeading6 style={{ color: '#191919', fontSize: '18px' }} >
                        {props.title}
                    </GelHeading6>
                    <GelIcon
                        name={isExpanded ? "ChevronUp" : "ChevronDown"}
                        color={getGelTokens().global.themeColorBackgroundSuccess}
                        width="20px"
                        height="20px"
                    />
                </GelBoxLayout>
            </GelContainerLite>
            <GelContainerLite
                style={{
                    borderBottom: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                    borderLeft: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                    borderRight: `${getGelTokens().global.sizeBaseUnit / 4}px solid ${getGelTokens().global.themeColorBorderDefault}`,
                }}
            >
                <GelCollapsible open={isExpanded}>
                    <GelContainerLite style={{ padding: '8px 0px' }}>
                        <GelParagraph
                            style={{
                                fontWeight: '400px',
                                color: `#666666`
                            }}
                        >
                            <div dangerouslySetInnerHTML={{ __html: props.body }} />
                        </GelParagraph>
                    </GelContainerLite>
                </GelCollapsible>
            </GelContainerLite>
        </div>
    );
};

export default Accordion;
